import React from 'react'
import { Pie } from '@antv/g2plot'
import Box from '@mui/material/Box'
import { Card, CardContent, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const DashboardPies = React.forwardRef(
  (
    props: {
      data: object[]
      title: string
      interactions: any[]
      setProjectIds?: any
    },
    ref
  ) => {
    const [data, setData] = React.useState<object | null>(null)
    const his = React.useRef<HTMLElement | string>('')
    const plot = React.useRef<Pie>()
    const { t } = useTranslation()

    React.useEffect(() => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      plot.current = new Pie(his.current, {
        data: props.data.map((item, index) => {
          item = {
            ...item,
            key: index
          }

          return item
        }),
        appendPadding: 10,
        angleField: 'value',
        colorField: 'type',
        radius: 0.9,
        label: {
          type: 'inner',
          offset: '-30%',
          content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
          style: {
            fontSize: 14,
            textAlign: 'center'
          }
        },
        interactions: props.interactions
      })

      plot.current.on('plot:click', (e: any) => {
        if (e.data && e.data.data) {
          // one
          const index = e.data.data.key
          const state = plot.current?.chart.getElements()[index].getStates()

          console.log(e.data?.data?.key)

          if (state && state.includes('selected')) {
            setData(e.data?.data?.key)

            props && props.setProjectIds(e.data?.data?.key)
          } else {
            setData(null)

            props && props.setProjectIds(null)
          }
        }
      })
    }, [])

    React.useEffect(() => {
      plot.current?.render()
    }, [])

    React.useImperativeHandle(ref, () => {
      return {
        data,
        setData,
        plot
      }
    })

    return (
      <Card
        sx={{
          height: '100%'
        }}
      >
        <CardContent
          sx={{ '& .apexcharts-xcrosshairs.apexcharts-active': { opacity: 0 } }}
        >
          <Typography textAlign={'center'}>{t(props.title)}</Typography>

          <Box ref={his} height={'220px'}></Box>
        </CardContent>
      </Card>
    )
  }
)

export default DashboardPies
