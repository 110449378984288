import React from 'react'
import { Column } from '@antv/g2plot'
import Box from '@mui/material/Box'
import { Card, CardContent, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { salesData } from '../StatisticsCard'

const DashboardColumn = React.forwardRef(
  (props: { data: object[]; title: string }, ref) => {
    const [data, setData] = React.useState<object | null>(null)
    const his = React.useRef<HTMLElement | string>('')
    const plot = React.useRef<Column>()
    const { t } = useTranslation()

    React.useEffect(() => {
      let sun = -1
      const color = ['#F78065', '#F5A214', '#9D7BDB', '#4AD4BB', '#92D419']

      // eslint-disable-next-line react-hooks/exhaustive-deps
      plot.current = new Column(his.current, {
        data: props.data.map((item, index) => {
          item = {
            ...item,
            key: index
          }

          return item
        }),
        xField: 'type',
        yField: 'value',
        minColumnWidth: 40,
        maxColumnWidth: 40,
        color: () => {
          sun++

          return color[sun] ?? '#F78065'
        },
        label: {
          // 可手动配置 label 数据标签位置
          position: 'middle', // 'top', 'bottom', 'middle',
          // 配置样式
          style: {
            fill: 'black',
            fontSize: 12
          }
        },
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: false
          }
        },
        meta: {
          type: {
            alias: t('gene')
          },
          value: {
            alias: t('count')
          }
        }
      })
    }, [])

    React.useEffect(() => {
      plot.current?.render()
    }, [])

    React.useImperativeHandle(ref, () => {
      return {
        data,
        setData,
        plot
      }
    })

    return (
      <Card
        sx={{
          height: '100%'
        }}
      >
        <CardContent
          sx={{ '& .apexcharts-xcrosshairs.apexcharts-active': { opacity: 0 } }}
        >
          <Typography textAlign={'center'}>{t(props.title)}</Typography>

          <Box ref={his} height={'220px'}></Box>
        </CardContent>
      </Card>
    )
  }
)

export default DashboardColumn
