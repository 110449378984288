// ** React Imports
import { ReactElement } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Avatar from '@mui/material/Avatar'
import CardHeader from '@mui/material/CardHeader'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'

// ** Icons Imports
import TrendingUp from 'mdi-material-ui/TrendingUp'
import CurrencyUsd from 'mdi-material-ui/CurrencyUsd'
import DotsVertical from 'mdi-material-ui/DotsVertical'
import CellphoneLink from 'mdi-material-ui/CellphoneLink'
import AccountOutline from 'mdi-material-ui/AccountOutline'

// ** Types
import { ThemeColor } from 'src/@core/layouts/types'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/material'

interface DataType {
  stats: string
  title: string
  color: string
  icon: ReactElement
  key: string
}

export const salesData: DataType[] = [
  {
    stats: '',
    title: 'WES',
    key: 'WES',
    color: '#4AD4BB',
    icon: <AccountOutline sx={{ fontSize: '1.75rem' }} />
  },
  {
    stats: '',
    title: 'WGS',
    key: 'WGS',
    color: '#92D419',
    icon: <TrendingUp sx={{ fontSize: '1.75rem' }} />
  },
  {
    stats: '',
    color: '#F5A214',
    title: 'RNA',
    key: 'RNA',
    icon: <CellphoneLink sx={{ fontSize: '1.75rem' }} />
  },
  {
    stats: '',
    color: '#F78065',
    title: 'MicroArray',
    key: 'MicroArray',
    icon: <CurrencyUsd sx={{ fontSize: '1.75rem' }} />
  },
  {
    stats: '',
    color: '#9D7BDB',
    title: 'Panel',
    key: 'Gene_panel',
    icon: <CurrencyUsd sx={{ fontSize: '1.75rem' }} />
  }
]

const statsVal = (item: string, chartInfo: any) => {
  if (chartInfo) return chartInfo[item.toLowerCase()]
}

const renderStats = (chartInfo: any, t: any) => {
  return salesData.map((item: DataType, index: number) => (
    <Grid item xs={6} sm={2.4} key={index}>
      <Card>
        <CardContent
          sx={{
            padding: '0 !important'
          }}
        >
          <Box
            pb={4}
            pt={2}
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              bgcolor: '#F6F7FC'
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="caption" fontWeight={650}>
                {/* {item.title} */}
              </Typography>

              <Typography variant="h6">
                {statsVal(item.key, chartInfo)}

                {` ${t('cases')}`}
              </Typography>
            </Box>

            <Avatar
              variant="rounded"
              sx={{
                mr: 1,
                width: 85,
                height: 40,
                boxShadow: 3,
                color: 'common.white',
                fontSize: '14px',
                backgroundColor: `${item.color}`
              }}
            >
              {item.title}
            </Avatar>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  ))
}
interface Props {
  wgs: 0
  wgs_week: 0
  wgs_month: 0
  wes: 0
  wes_week: 0
  wes_month: 0
  rna: 0
  rna_week: 0
  rna_month: 0
  microarray: 0
  microarray_week: 0
  microarray_month: 0
  gene_panel: 0
  gene_panel_week: 0
  gene_panel_month: 0
  totalCount: 0
  monthlyCount: []
}

const StatisticsCard = ({ samplesData }: any) => {
  const chartInfo = samplesData
  const { t } = useTranslation()

  return (
    <Card sx={{ height: '100%' }}>
      {/* <CardHeader
        title={t('Sample types')}
        subheader={
          <Typography variant="body2" display={'flex'} justifyContent="center">
            <Box component="span" sx={{ color: 'text.primary' }}>
              {t('Sample of the Week ')}

              <span
                style={{
                  fontWeight: 500,
                  fontSize: 22,
                  marginLeft: 10
                }}
              >
                {chartInfo?.wgs_week +
                  chartInfo?.wes_week +
                  chartInfo?.rna_week +
                  chartInfo?.microarray_week +
                  chartInfo?.gene_panel_week}
              </span>
            </Box>
          </Typography>
        }
        titleTypographyProps={{
          sx: {
            mb: 3,
            letterSpacing: '0.15px !important',
            '::before': {
              content: '""',
              display: 'inline-block',
              width: 4,
              height: '1.6rem',
              bgcolor: 'primary.main',
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              left: '-15px'
            },
            lineHeight: '1.6rem',
            position: 'relative',
            ml: '15px'
          }
        }}
      /> */}

      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Typography
          variant="h6"
          sx={{
            '::before': {
              content: '""',
              display: 'inline-block',
              width: 4,
              height: '1.6rem',
              bgcolor: 'primary.main',
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              left: '-15px'
            },
            lineHeight: '1.6rem',
            position: 'relative',
            ml: '15px'
          }}
        >
          {t('Sample types')}
        </Typography>

        <Stack flex={1} justifyContent="space-between">
          <Box pt={10}>
            <Typography
              variant="body2"
              display={'flex'}
              justifyContent="center"
            >
              <Box component="span" sx={{ color: 'text.primary' }}>
                {t('Sample of the Week ')}

                <span
                  style={{
                    fontWeight: 500,
                    fontSize: 28,
                    marginLeft: 10
                  }}
                >
                  {chartInfo?.wgs_week +
                    chartInfo?.wes_week +
                    chartInfo?.rna_week +
                    chartInfo?.microarray_week +
                    chartInfo?.gene_panel_week}

                  {` ${t('cases')}`}
                </span>
              </Box>
            </Typography>
          </Box>

          <Grid container spacing={5}>
            {renderStats(chartInfo, t)}
          </Grid>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default StatisticsCard
