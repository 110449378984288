// ** MUI Imports
import Grid from '@mui/material/Grid'

// ** Icons Imports
import Poll from 'mdi-material-ui/Poll'
import CurrencyUsd from 'mdi-material-ui/CurrencyUsd'
import HelpCircleOutline from 'mdi-material-ui/HelpCircleOutline'
import BriefcaseVariantOutline from 'mdi-material-ui/BriefcaseVariantOutline'

// ** Custom Components Imports
import CardStatisticsVerticalComponent from 'src/@core/components/card-statistics/card-stats-vertical'

// ** Styled Component Import
import ApexChartWrapper from 'src/@core/styles/libs/react-apexcharts'

// ** Demo Components Imports
import Table from 'src/views/dashboard/Table'
import Trophy from 'src/views/dashboard/Trophy'
import TotalEarning from 'src/views/dashboard/TotalEarning'
import StatisticsCard from 'src/views/dashboard/StatisticsCard'
import MonthlyOverview from '@/views/dashboard/MonthlyOverview'
import DepositWithdraw from 'src/views/dashboard/DepositWithdraw'
import SalesByCountries from 'src/views/dashboard/SalesByCountries'
import { useQuery } from 'react-query'
import {
  fetchSample,
  fetchSamplesCharts,
  fetchUsersCharts,
  getDashbordData
} from '@/@core/services/app.service'
import DashboardSearch from '@/views/dashboard/Search'
import { isWindow } from '@/@core/utils/utils'
import Pies from '@/views/analyze/children/quality-control-children/Pie'
import DashboardPies from '@/views/dashboard/plot-children/Pie'
import DashboardColumn from '@/views/dashboard/plot-children/Column'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import React from 'react'
import { NObject } from '@/@core/components/filter-variants'

export default function Dashboard() {
  const { t } = useTranslation()
  const [projectId, setProjectId] = React.useState<number>()
  const pieRef1 = React.useRef<any>()
  const pieRef2 = React.useRef<any>()
  const pieRef3 = React.useRef<any>()

  const { data: samplesData } = useQuery(
    ['samplesData'],
    async () => {
      return await fetchSamplesCharts()
    },
    {
      keepPreviousData: true
    }
  )

  const pagination = {
    pageNum: 0,
    pageSize: 10
  }

  const { data: samplesList } = useQuery(
    ['samplesList'],
    async () => {
      const query: NObject = {
        ...pagination,
        pageNum: pagination.pageNum + 1
      }

      return await fetchSample(query)
    },
    {
      keepPreviousData: true
    }
  )

  const { data: sampleDashbordData } = useQuery(
    ['sampleDashbordData', projectId],
    async () => {
      const query: NObject = {}

      if (projectId || projectId === 0) {
        query.projectId = `${projectId}`
      }

      return await getDashbordData(query)
    },
    {
      keepPreviousData: true,
      onSuccess: (data) => {
        //

        const piesData2 = data.gender.map(
          (item: { title: any | string | string[]; value: any }) => {
            return {
              type: t(item.title),
              value: item.value
            }
          }
        )

        const piesData3 = data.reportResult.map(
          (item: { title: any | string | string[]; value: any }) => {
            return {
              type: t(item.title),
              value: item.value
            }
          }
        )

        console.log('123', data)

        console.log('456', piesData2, piesData3)

        pieRef2.current &&
          pieRef2.current.plot &&
          pieRef2.current.plot.current &&
          pieRef2.current.plot.current.changeData &&
          pieRef2.current.plot.current.changeData(piesData2)

        pieRef3.current &&
          pieRef3.current.plot &&
          pieRef3.current.plot.current &&
          pieRef3.current.plot.current.changeData &&
          pieRef3.current.plot.current.changeData(piesData3)
      }
    }
  )

  if (typeof window === 'undefined') {
    return <></>
  } else {
    return (
      <ApexChartWrapper
        sx={{
          height: '100%',
          padding: '0 25px',
          backgroundColor: 'rgb(242,244,245)',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Grid container spacing={4}>
          <Grid maxHeight={300} item xs={12} md={4} lg={2}>
            <Trophy samplesData={samplesData} samplesList={samplesList} />
          </Grid>

          <Grid item maxHeight={isWindow() ? 300 : 350} xs={12} md={8} lg={6}>
            <StatisticsCard samplesData={samplesData} />
          </Grid>

          <Grid item maxHeight={300} xs={12} md={12} lg={4}>
            <MonthlyOverview samplesData={samplesData} />
          </Grid>
        </Grid>

        <Grid container spacing={4} mt={4}>
          <Grid item maxHeight={300} xs={12} md={4}>
            {sampleDashbordData && sampleDashbordData.project ? (
              <DashboardPies
                ref={pieRef1}
                setProjectIds={(id: number) => {
                  setProjectId(id)
                }}
                title="Statistical project category"
                data={sampleDashbordData.project.map(
                  (item: { name: any; count: any; id: any }) => {
                    return {
                      id: item.id,
                      type: item.name,
                      value: item.count
                    }
                  }
                )}
                interactions={[
                  { type: 'element-active' },
                  { type: 'element-single-selected' }
                ]}
              />
            ) : (
              ''
            )}
          </Grid>

          <Grid maxHeight={300} item xs={12} md={4}>
            {sampleDashbordData && sampleDashbordData.gender ? (
              <DashboardPies
                ref={pieRef2}
                title="Gender statistics"
                data={sampleDashbordData.gender.map(
                  (item: { title: any | string | string[]; value: any }) => {
                    return {
                      type: t(item.title),
                      value: item.value
                    }
                  }
                )}
                interactions={[{ type: 'element-active' }]}
              />
            ) : (
              ''
            )}
          </Grid>

          <Grid item maxHeight={300} xs={12} md={4}>
            {sampleDashbordData && sampleDashbordData.reportResult ? (
              <DashboardPies
                ref={pieRef3}
                title="Sample label statistics"
                data={sampleDashbordData.reportResult.map(
                  (item: { title: any | string | string[]; value: any }) => {
                    return {
                      type: t(item.title),
                      value: item.value
                    }
                  }
                )}
                interactions={[{ type: 'element-active' }]}
              />
            ) : (
              ''
            )}
          </Grid>
        </Grid>

        <Grid container spacing={4} mt={4}>
          <Grid maxHeight={300} item xs={12} md={6}>
            {sampleDashbordData && sampleDashbordData.topFiveKeyFinding ? (
              <DashboardColumn
                title="Main findings TOP5 gene"
                data={sampleDashbordData.topFiveKeyFinding.map(
                  (item: { gene: any; count: any }) => {
                    return {
                      type: item.gene,
                      value: item.count
                    }
                  }
                )}
              />
            ) : (
              ''
            )}
          </Grid>

          <Grid item maxHeight={300} xs={12} md={6}>
            {sampleDashbordData &&
            sampleDashbordData.topFiveIncidentalDiscovery ? (
              <DashboardColumn
                title="The secondary found TOP5 genes"
                data={sampleDashbordData.topFiveIncidentalDiscovery.map(
                  (item: { gene: any; count: any }) => {
                    return {
                      type: item.gene,
                      value: item.count
                    }
                  }
                )}
              />
            ) : (
              ''
            )}
          </Grid>
        </Grid>

        <Grid container mt={4} flex={1}>
          <Grid item xs={12} md={12} lg={12}>
            <DashboardSearch samplesList={samplesList} />
          </Grid>
        </Grid>
      </ApexChartWrapper>
    )
  }
}
