// ** MUI Imports
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import { styled, useTheme } from '@mui/material/styles'
import { useState } from 'react'
import router from 'next/router'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Stack
} from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { TransitionProps } from '@mui/material/transitions'
import CreateSample from '../samples/create-sample/CreateSamples'
import LoadingButton from '@mui/lab/LoadingButton'

// Styled component for the triangle shaped background image
const TriangleImg = styled('img')({
  right: 0,
  bottom: 0,
  height: 170,
  position: 'absolute'
})

// Styled component for the trophy image
const TrophyImg = styled('img')({
  right: 36,
  bottom: 20,
  height: 98,
  position: 'absolute'
})

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />
})

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    maxWidth: 'none'
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  },
  '& .MuiDialog-container': {
    alignItems: 'start',
    paddingTop: '10vh'
  }
}))

const Trophy = ({ samplesData, samplesList }: any) => {
  // ** Hook
  const theme = useTheme()

  const chartInfo = samplesData
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [modalValue, setModalValue] = React.useState(0)
  const createSampleRef = React.useRef<any>()

  const handleClose = () => {
    setOpen(false)
  }

  console.log('samplesData', samplesData)

  const imageSrc =
    theme.palette.mode === 'light' ? 'triangle-light.png' : 'triangle-dark.png'

  return (
    <Card sx={{ position: 'relative', height: '100%' }}>
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Typography
          variant="h6"
          sx={{
            '::before': {
              content: '""',
              display: 'inline-block',
              width: 4,
              height: '1.6rem',
              bgcolor: 'primary.main',
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              left: '-15px'
            },
            lineHeight: '1.6rem',
            position: 'relative',
            ml: '15px'
          }}
        >
          {t('Sample capacity')}
        </Typography>

        {/* <Typography variant="body2" sx={{ letterSpacing: '0.25px' }}>
          {t('Total sample size in the system')}
        </Typography> */}

        <Stack
          my={8}
          direction="row"
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            gap: '1.5rem',
            pl: 5,
            flex: 1
          }}
        >
          <Box>
            <img src="/images/pages/Group 34file.png" />
          </Box>

          <Box>
            <Typography sx={{ fontSize: 16 }}>{t('Total sample')}</Typography>

            <Typography sx={{ fontSize: 28, fontWeight: 500 }}>
              {samplesList?.count || 0}

              {t('cases')}
            </Typography>
          </Box>
        </Stack>

        <Box
          textAlign={'center'}
          position="absolute"
          bottom={0}
          left={'50%'}
          sx={{
            transform: 'translate(-50%,-50%)',
            zIndex: 99,
            display: 'flex',
            width: '100%',
            justifyContent: 'space-evenly'
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              router.push(`/samples`)
            }}
          >
            {t('View sample')}
          </Button>

          <Button
            variant="contained"
            onClick={() => {
              setOpen(true)
            }}
          >
            {`${t('create')}${t('sample')}`}
          </Button>
        </Box>

        <TriangleImg
          alt="triangle background"
          src={`/images/misc/${imageSrc}`}
        />

        <BootstrapDialog
          aria-labelledby="customized-dialog-title"
          open={true}
          sx={{
            display: open ? 'block' : 'none'
          }}
          TransitionComponent={Transition}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {t('Upload Sample')}
          </DialogTitle>

          <IconButton
            aria-label="close"
            onClick={() => handleClose()}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>

          <DialogContent dividers>
            <Box width={'100%'}>
              <CreateSample
                fn={() => {
                  handleClose()

                  setModalValue(Math.random())
                }}
                setLoading={setLoading}
                ref={createSampleRef}
                key={modalValue}
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <Stack
              direction={'row'}
              spacing={4}
              sx={{
                mt: 4,
                mb: 4,
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Button
                color="secondary"
                variant="outlined"
                onClick={handleClose}
              >
                {t('cancel')}
              </Button>

              <LoadingButton
                variant="contained"
                loading={loading}
                color={'primary'}
                onClick={async (e) => {
                  e.stopPropagation()

                  setLoading(true)

                  await (createSampleRef.current &&
                    createSampleRef.current.save())

                  router.push(`/samples`)
                }}
              >
                {t('Submit')}
              </LoadingButton>
            </Stack>
          </DialogActions>
        </BootstrapDialog>

        {/* <TrophyImg alt="trophy" src="/images/misc/trophy.png" /> */}
      </CardContent>
    </Card>
  )
}

export default Trophy
